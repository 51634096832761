<template>
  <div class="calendar-month template-1">
    <page-header class="mx-lg">
      <h1 v-if="stateMode">{{ translations.tcStateCalendarList }}</h1>
      <h1 v-else>{{ translations.tcCountryCalendarList }}</h1>
    </page-header>
    <!-- / Page Header -->
    <camp-selector
      @camp_changed="page_load($event)"
      :i18n="translations.components"
      :showCamp="false"
      :tooltip="translations.tcCurrentHome"
    />
    <!-- / Camp Selector -->
    <page-body class="mx-lg">
      <header>
        <div class="cal-key-cont">
          <CalendarKey
            v-for="(eve, index) in EventKey"
            :key="index"
            :obj="eve"
            :index="index"
            :i18n="translations.components"
          />
        </div>
      </header>
      <section class="section-1 bg-block">
        <CalendarToolbar
          :switch_toggle="switchM"
          @toggleView="switchView"
          @filterby="filterMeeting"
          :show_switch="false"
          @changestateregion="changestateregion"
          @changestatearea="changestatearea"
          :i18n="translations.components"
          :translationsAll="translations"
          :stateMode="stateMode"
        />
        <section :class="switchM == 'grid' ? 'fullcalvis' : 'fullcalinv'">
          <FullCalendar ref="fullcalendar" :options="calendarOptions"></FullCalendar>
        </section>
        <div class="month-view" v-if="switchM == 'month'">
          <div class="day-view" v-for="(day, date) in days" :key="date">
            <h3>{{ printLowDate(date) }}</h3>
            <template v-for="(ev, index) in day">
              <CalendarWeek :obj="ev" :key="index" @onSelected="getSelected" />
            </template>
          </div>
        </div>
      </section>
    </page-body>
  </div>
  <!-- / Page Body -->
</template>

<script>
/* eslint-disable */
import { mapActions, mapState, mapGetters } from 'vuex'
import { Calendar } from 'vue-bootstrap4-calendar'
import CalendarKey from '@/components/calendar/calendar-key/CalendarKey.vue'
import { calendarMixin } from '@/mixins/calendarMixin'
import CalendarWeek from '@/components/calendar/calendar-week/CalendarWeek.vue'
import calendarService from '../../../services/calendarService'
import CalendarToolbar from '@/components/calendar/calendar-toolbar/CalendarToolbar.vue'
import CampSelect from '@/components/CampSelect.vue'
import constantData from '@/json/data.json'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import iClose from '@/assets/svgs/icon-close.vue'
import iAdd from '@/assets/svgs/icon-add-button.vue'
import { Loader } from '@/mixins/Loader'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'state-event-grid',
  data() {
    return {
      translations: {},
      glabel: null,
      toBeScheduled: [],
      dataModal: null,
      isOpen: false,
      switchM: 'month',
      toBeScheduled: true,
      events: [],
      tempEvents: [],
      tempFilter: [],
      selectedArea: null,
      EventKey: [
        {
          color: '#003946',
          type: 'Gideon',
        },
        {
          color: '#E43300',
          type: 'Auxiliary',
        },
        {
          color: '#290048',
          type: 'Both',
        },
      ],
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        initialView: 'dayGridMonth',
        handleWindowResize: true,
        allDaySlot: false,
        eventDisplay: 'block',
        selectable: true,
        weekends: true,
        eventClick: this.selectedEvent,
        contentHeight: 580,
      },
    }
  },
  methods: {
    ...mapActions({
      clearSelected: 'eventCalendar/clearSelected',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedConventionKey: 'user/setSelectedConventionKey',
      setSelectedMeeting: 'eventCalendar/setSelectedMeeting',
      setSelectedMeetingKey: 'user/setSelectedMeetingKey',
    }),
    async page_load(evt) {
      if (!evt) {
        try {
          if (!this.loading) this.setLoadingStatus(true)
          await this.getMeetings(this.officerToolbarSelected.country_state, null, null)
          //apply default state area filter
          let eventList = []

          if (!!this.selectedArea) {
            //apply user's state area filter based on camp
            this.tempEvents.forEach((item) => {
              if (!!item.distribution) {
                let dist = item.distribution.filter(
                  (x) => x.mgd_children_org_key.toString().toUpperCase() == this.selectedArea.value.toUpperCase()
                )
                if (dist.length > 0) {
                  item.distributionSelected = dist
                  item.label = this.selectedArea.text
                  eventList.push(item)
                }
              }
            })
            this.events = eventList
            if (!!this.$refs.fullcalendar) {
              let calendar = this.$refs.fullcalendar.getApi()
              if (calendar.getEventSources().length > 0) {
                calendar.getEventSources()[0].remove()
              }
              calendar.addEventSource(this.events)
            }
          }
        } catch (e) {
          console.error('Error in StateCalendarEvent, page_load(), ', e)
        } finally {
          this.setLoadingStatus(false)
        }
      }
    },
    eventAdded(event) {
      alert(event)
    },
    eventDeleted(event) {
      this.events.splice(this.events.indexOf(event), 1)
    },
    switchMonth() {
      this.switchMonth = 'switch'
    },
    switchView(val) {
      this.switchM = val
    },
    printLowDate(num) {
      let dateEpoch = new Date(0)
      return this.returnFormattedDate(dateEpoch.setUTCSeconds(Math.floor(num / 1000)))
    },
    async getMeetings(org_key, start_date, end_date) {
      this.showLoader()
      await calendarService
        .getStateMeetings(org_key, start_date, end_date)
        .then((res) => {
          this.getToBeScheduleMeeting(org_key)
          this.events = res.data.meetings.map((item) => {
            item.color =
              item.vmpt_trn_txt === 'Gideon'
                ? 'card-Gideon'
                : item.vmpt_trn_txt === 'Auxiliary'
                ? 'card-Auxillary'
                : 'card-Both'
            return this.meetingDetails(item)
          })
          this.tempEvents = this.events
          if (!!this.$refs.fullcalendar) {
            let calendar = this.$refs.fullcalendar.getApi()
            if (calendar.getEventSources().length > 0) {
              calendar.getEventSources()[0].remove()
            }
            calendar.addEventSource(this.events)
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.closeLoader()
        })
    },
    async getSelected(itemSelected) {
      let evt = {}
      if (!!itemSelected.event) {
        evt = itemSelected.event
        Object.assign(evt, evt.extendedProps)
      } else {
        evt = itemSelected
      }
      if (evt.mtrkey !== null && evt.mtrkey !== constantData.empty_guid) {
        this.showLoader()
        let response = await calendarService.getMeetingRecurrence(evt.mtrkey)
        if (response.status === 200) {
          evt.reccurrencetype = response.data
          await this.clearSelected()
          await this.setSelectedMeeting(evt)
          this.closeLoader()
          this.$router.push({
            path: '/calendar/state/event/event-detail/',
          })
        } else {
          this.closeLoader()
        }
      } else {
        // check if a state convention  is selected
        switch (evt.meetingtype) {
          case constantData.meetings.stateconvention.value:
            this.setSelectedConventionKey(evt.mtgkey)
            this.closeLoader()
            this.$router.push({ path: constantData.meetings.stateconvention.route })
            break
          case constantData.meetings.TwoHourConversation.value:
          case constantData.meetings.TwoDayInstructor.value:
          case constantData.meetings.TwoDayEducationalUnit.value:
            await this.setSelectedMeetingKey(evt.mtgkey)
            this.$router.push({ path: constantData.meetings.TwoHourConversation.route })
            break
          default:
            await this.setSelectedMeeting(evt)
            this.closeLoader()
            this.$router.push({ path: '/calendar/state/event/event-detail/' })
            break
        }
      }
    },
    filterMeeting(obj) {
      if (obj.type === 'member') {
        if (obj.value === 'all') {
          this.events = this.tempEvents
        } else {
          this.events = this.tempEvents.filter((item) => {
            return !!item.vmpt_trn_txt && item.vmpt_trn_txt.toLowerCase() === obj.value
          })
        }
        if (!!this.$refs.fullcalendar) {
          let calendar = this.$refs.fullcalendar.getApi()
          calendar.getEventSources()[0].remove()
          calendar.addEventSource(this.events)
        }
      } else if (obj.type === 'daterange') {
        this.getMeetings(this.officerToolbarSelected.country_state, obj.value.startDate, obj.value.endDate)
      }
    },
    getToBeScheduleMeeting(org_key) {
      calendarService.MeetingToBeSchedule(org_key).then((res) => {
        if (res.status == 200) {
          this.toBeScheduled = res.data.meeting
        }
      })
    },
    addToBeScheduleEvent(event) {
      this.$router.push({
        path: `/calendar/camp-calendar/add-event/${event.vmtt_mtt_key.toLowerCase()}`,
      })
    },
    async selectedEvent(event) {
      await this.getSelected(event)
    },
    changestateregion(payload) {
      this.glabel = payload.text
      if (payload.value === 'all') {
        this.tempFilter = []
        this.events = this.tempEvents.map((item) => {
          item.label = null
          return item
        })
      } else {
        let eventList = []
        this.tempEvents.forEach((item) => {
          if (!!item.distribution) {
            let dist = item.distribution.filter(
              (x) => x.mgd_parent_org_key.toString().toUpperCase() == payload.value.toUpperCase()
            )
            if (dist.length > 0) {
              item.distributionSelected = dist
              item.label = payload.text
              eventList.push(item)
            }
          }
        })
        this.events = eventList
        this.tempFilter = eventList
      }
      if (!!this.$refs.fullcalendar) {
        let calendar = this.$refs.fullcalendar.getApi()
        calendar.getEventSources()[0].remove()
        calendar.addEventSource(this.events)
      }
    },
    changestatearea(payload) {
      if (payload.value === 'all') {
        if (this.tempFilter === []) {
          this.events = this.tempEvents.map((item) => {
            item.label = this.glabel
            return item
          })
        } else {
          this.events = this.tempFilter.map((item) => {
            item.label = this.glabel
            return item
          })
        }
      } else {
        let eventList = []

        if (!this.tempFilter) {
          if (this.tempEvents.length === 0) {
            //getMeetings will filter
            this.selectedArea = { value: payload.value.toUpperCase(), text: payload.text }
          }
          this.tempEvents.forEach((item) => {
            if (!!item.distribution) {
              let dist = item.distribution.filter(
                (x) => x.mgd_children_org_key.toString().toUpperCase() == payload.value.toUpperCase()
              )
              if (dist.length > 0) {
                item.distributionSelected = dist
                item.label = payload.text
                eventList.push(item)
              }
            }
          })
        } else {
          this.tempFilter.forEach((item) => {
            let dist = item.distribution.filter(
              (x) => x.mgd_children_org_key.toString().toUpperCase() == payload.value.toUpperCase()
            )
            if (dist.length > 0) {
              item.distributionSelected = dist
              item.label = this.glabel + ' >> ' + payload.text
              eventList.push(item)
            }
          })
        }
        this.events = eventList
      }
      if (!!this.$refs.fullcalendar) {
        let calendar = this.$refs.fullcalendar.getApi()
        calendar.getEventSources()[0].remove()
        calendar.addEventSource(this.events)
      }
    },
    loadTranslations() {
      this.EventKey[0].type = this.translations.tcGideon
      this.EventKey[1].type = this.translations.tcAuxiliary
      this.EventKey[2].type = this.translations.tcBoth
    },
  },
  mixins: [calendarMixin, Loader, translationMixin],
  computed: {
    ...mapState(['user']),
    ...mapGetters({
      loading: 'menu/loadingStatus',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
    }),

    stateMode() {
      return (
        !!this.officerToolbarSelected.camp_number && this.officerToolbarSelected.camp_number.substring(0, 1) === 'U'
      )
    },

    days() {
      //   Used to display date on grid display
      const days = {}
      this.events.forEach((ev) => {
        const meetingGuid = ev.mtg_mtt_key ? ev.mtg_mtt_key.toLowerCase() : ''
        ev.title = this.translations.common['event-types'].hasOwnProperty(meetingGuid)
          ? this.translations.common['event-types'][meetingGuid]
          : ev.meetingtitle
        if (!days[ev.date]) {
          days[ev.date] = []
        }
        days[ev.date].push(ev)
      })
      return days
    },
  },
  async created() {
    await this.clearSelected()
    try {
      await Promise.all([
        this.setLoadingStatus(true),
        this.getViewTranslations('forms', 'geographic-areas'),
        this.getComponentTranslations('calendar-key', 'calendar-toolbar', 'camp-select'),
        this.getComponentTranslations('common.calendar-event-type', 'common.calendar-event-type-state'),
      ]).then((results) => {
        this.stripReadableText(results[3])
        this.translations.common['event-types'] = {
          // merge event types together
          ...this.translations.common['calendar-event-type'],
          ...this.translations.common['calendar-event-type-state'],
        }
        const translatedText = {
          ...results[2],
          common: {
            ...this.translations.common,
          },
        }
        this.$set(this.translations, 'components', translatedText)
        this.loadTranslations()
        this.page_load()
      })
    } catch (e) {
      console.error('Error in StateCalendarEvent, created()', e)
    } finally {
    }
  },
  components: {
    Calendar: Calendar,
    CalendarKey: CalendarKey,
    CalendarToolbar: CalendarToolbar,
    CalendarWeek: CalendarWeek,
    campSelector: CampSelect,
    FullCalendar,
    iAdd: iAdd,
    iClose: iClose,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
.fullcalvis {
  visibility: visible;
  height: auto;
}
.fullcalinv {
  visibility: hidden;
  height: 0px;
}
.fc-event {
  font-size: 0.85em !important;
  background-color: #3a87ad;
  border-color: #3a87ad;
  line-height: 1.3;
  border-radius: 3px;
  border: 1px solid #3a87ad;
  cursor: pointer;
}
.fc-event-time {
  min-width: fit-content;
}
.fc .fc-col-header-cell-cushion {
  color: #636363;
  text-decoration: none;
}
.fc-timegrid-event {
  font-size: 0.9em !important;
  min-height: 27px;
  line-height: 0.95em;
}
.fc-daygrid-day-number {
  display: flex;
  text-decoration: none !important;
  justify-content: center;
  align-items: center;
  float: right;
  width: 30px;
  height: 30px;
  font-size: 14px;
  color: #636363;
  font-family: $open-sans;
  @include breakpoint(sm) {
    margin: 0 auto;
    float: none;
  }
}
.to-be-scheduled {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fbc301;
  header {
    display: flex;
    h3 {
      color: #000;
      font-family: 'DIN 1451 W01 Engschrift', sans-serif;
      font-size: 30px;
      letter-spacing: 1px;
      line-height: 44px;
      text-transform: uppercase;
      flex: 1 1 auto;
      display: flex;
      @include breakpoint(sm) {
        justify-content: center;
      }
    }
    .i-tooltip {
      position: relative;
      top: 0px;
      margin-left: 10px;
      line-height: 0;
      svg path {
        fill: #000;
      }
    }
  }
  .scheduled-buttons {
    .btn {
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      @include breakpoint(sm) {
        width: 100%;
        text-align: left;
        span {
          flex: 1 1 auto;
        }
      }
      svg {
        margin-left: 7px;
      }
    }
  }
}
.calendar-month {
  margin-bottom: 80px;
  .section-1 {
    padding-top: 30px;
    @include breakpoint(sm) {
      margin-top: 50px;
    }
  }
  .cal-key-cont {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
    margin-bottom: 30px;
    .calendar-key:last-of-type .event-key {
      margin-right: 0;
    }
    @include breakpoint(sm) {
      display: none;
    }
  }
  .month-view {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    @include breakpoint(sm) {
      padding-left: 0;
      padding-right: 0;
    }
    h3 {
      color: #000;
      font-size: 42px;
      line-height: 44px;
      margin-bottom: 30px;
      text-transform: none;
      @include breakpoint(sm) {
        padding-left: 55px;
        padding-right: 55px;
      }
    }
    .day-view {
      padding-bottom: 85px;
      @include breakpoint(sm) {
        padding-bottom: 30px;
      }
    }
  }
  .card {
    padding: 0;
    box-shadow: none !important;
    .card-header {
      display: none;
    }
    .card-block {
      .row > .col-sm-12 > .row {
        display: none;
      }
      .full-calendar-body {
        margin-top: 0;
        .weeks {
          border-top: 1px solid #efefef;
          border-bottom: 1px solid #efefef;
          .week {
            font-size: 10px;
            color: #cfd5da;
            font-weight: normal;
            padding-top: 25px;
            padding-bottom: 25px;
            text-transform: uppercase;
            border-left: none;
            border-right: none;
          }
        }
        .week-row {
          border-color: #efefef;
          .day-cell {
            padding: 5px 5px;
            min-height: 150px;
            @include breakpoint(sm) {
              min-height: 100px;
              border: none;
              > .row > .col-sm-6 {
                &:first-of-type {
                  display: none;
                }
                &:last-of-type {
                  width: 100%;
                }
              }
            }
          }

          .today {
            background-color: #fff;
            .day-number {
              border-radius: 50%;
              background-color: #fbc301;
              color: #fff;
              padding-top: 0;
              padding-right: 0;
            }
          }
          .event-box {
            .card-header {
              display: block !important;
              font-size: 14px;
              padding: 8px 15px;
              border-bottom: none;
              @include breakpoint(sm) {
                display: none;
              }
            }
            .card {
              box-shadow: none !important;
              @include breakpoint(sm) {
                width: 13px;
                height: 13px;
                border: none !important;
                border-radius: 50%;
                background-color: #636363 !important;
                margin: 0 auto;
              }
              &.card-Gideon {
                background-color: #f1f4f5;
                border-left: 5px solid #003946;
                .card-header {
                  color: #003946;
                }
              }
              &.card-Auxillary {
                background-color: #fef4f1;
                border-left: 5px solid #fb4401;
                .card-header {
                  color: #fb4401;
                }
              }
              &.card-Both {
                background-color: #f3f1f5;
                border-left: 5px solid #2a0049;
                .card-header {
                  color: #2a0049;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
